// Всегда объявляем пути как методы, для консистентности

import { appRoute } from '@/shared/config'

// Helper for navigation through the application
class To {
  main = () => `${appRoute.main}`

  game = () => `/${appRoute.game}`

  matchPathWallet = () => `/${appRoute.wallet}`

  wallet = () => `/${appRoute.wallet}/${appRoute.walletInfo}`

  deposit = () => `/${appRoute.wallet}/${appRoute.deposit}`

  withdraw = () => `${appRoute.wallet}/${appRoute.withdraw}`

  history = () => `${appRoute.wallet}/${appRoute.history}`

  quests = () => `/${appRoute.quests}`

  wrench = () => `/${appRoute.wrench}`

  referral = () => `/${appRoute.referral}`
}

export const to = new To()
