import { to } from '@/shared/lib'

import Game from './controller.svg?react'
import Gift from './gift.svg?react'
import Location from './location.svg?react'
import Wallet from './wallet.svg?react'
import Wrench from './wernch.svg?react'

export interface MenuItemType {
  path: string;
  img: typeof Location;
  matchPathValue: string;
  disabled?: boolean;
}

export const menuItems: MenuItemType[] = [
  {
    path: to.game(),
    img: Game,
    matchPathValue: to.game(),
  },
  {
    path: to.wallet(),
    img: Wallet,
    matchPathValue: to.matchPathWallet(),
  },
  {
    path: to.referral(),
    img: Gift,
    matchPathValue: to.referral(),
    disabled: true,
  },
  {
    path: to.quests(),
    img: Location,
    matchPathValue: to.quests(),
  },
  {
    path: to.wrench(),
    img: Wrench,
    disabled: true,
    matchPathValue: to.wrench(),
  },
]
