import clsx from 'clsx'
import { forwardRef } from 'react'

import { FlexAlign, FlexDirection, FlexJustify, FlexProps } from '../flexTypes'
import s from './Flex.module.scss'

const alignClasses: Record<FlexAlign, string | undefined> = {
  end: s['flex_align--end'],
  center: s['flex_align--center'],
  start: s['flex_align--start'],
}

const justifyClasses: Record<FlexJustify, string | undefined> = {
  end: s['flex_justify--end'],
  center: s['flex_justify--center'],
  start: s['flex_justify--start'],
  between: s['flex_justify--between'],
}

const directionClasses: Record<FlexDirection, string | undefined> = {
  row: s['flex_direction--row'],
  column: s['flex_direction--column'],
}

export const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const {
    className,
    max,
    gap,
    children,
    justify = 'start',
    direction,
    style,
    align = 'center',
    ...otherProps
  } = props

  const classes = [
    className,
    justifyClasses[justify],
    alignClasses[align],
    directionClasses[direction],
  ]

  return (
    <div
      ref={ref}
      style={{ ...style, gap: `${gap}px` }}
      className={clsx(
        s.flex,
        classes,
        max && s['flex--max'])}
      {...otherProps}
    >
      {children}
    </div>
  )
})
